import React from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Text } from "crox-new-uikit";
import "./gadget.css";

const Lobby = () => {
    const navigate = useNavigate();
    return (
        <Flex
            flexDirection="column"
            alignItems="center"
            className="container-n cursor btnfos-1 btnfos-4"
            onClick={() => navigate("/lobby")}
        >
            <svg>
                <rect x="0" y="0" fill="none" width="100%" height="100%" />
            </svg>

            <Text fontSize="24px" color="#e2e2e2" mb={10} bold>
                P2X Auction House
            </Text>

            <lottie-player
                autoplay
                loop
                mode="normal"
                src="https://assets5.lottiefiles.com/private_files/lf30_t0igqye8.json"
                class="animation"
                style={{ height: "200px" }}
            />
        </Flex>
    );
};

export default Lobby;
